.contact-us__details {
  padding: 150px;
  background-color: #fef6f4;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.contact-us__details h5 {
  font-family: "Tenor Sans";
  color: #5a5a5a;
  font-size: 22px;
  line-height: 1.136em;
  text-transform: capitalize;
}
.contact-us__details p {
  color: #838383;
  margin-bottom: 0;
  font-family: "Montserrat";
  font-size: 1rem;
  font-weight: 100;
}
.contact-us__footer {
  padding: 100px 0;
  display: flex;
}
.contact-us__footer__map {
  width: 50%;
}
.contact-us__footer__form {
  width: 50%;
  padding: 0 150px;
}
.contact-us__footer__form h2 {
  font-family: "Tenor Sans";
  font-size: 2rem;
  text-transform: capitalize;
}
.contact-us__footer__form__inputs {
  display: flex;
  flex-direction: column;
}
.contact-us__footer__form__inputs input {
  outline: none;
  width: 100%;
  border: 0;
  border-radius: 0;
  padding: 6px 16px;
  margin-bottom: 12px;
  text-align: left;
  height: auto;
  font-size: 18px;
  font-family: "Cormorant", "serif";
  font-weight: 500;
  font-style: italic;
  line-height: 26px;
}
.contact-us__footer button {
  border: 1px solid #000;
  font-family: "Lato", "sans-serif";
  font-size: 12px;
  padding: 11px 29px;
  font-weight: 400;
  letter-spacing: 0.2em;
  background-color: white;
  line-height: 2em;
}

@media screen and (max-width: 550px) {
  .contact-us__img img {
    width: 100%;
  }
  .contact-us__details {
    flex-direction: column;
    padding: 107px 0;
    align-items: center;
    gap: 20px;
  }
  .contact-us__footer {
    flex-direction: column;
  }
  .contact-us__footer__form {
    width: 100%;
    padding: 10px 20px 50px;
  }
  .contact-us__footer__map {
    width: 100%;
    height: 450px;
  }
}

.termscondition{
    padding: 120px;
}
.termscondition h1{
    font-family: 'Tenor Sans';
    font-size: 2rem;
    line-height: 1.625rem;
    font-style: normal;
}
.termscondition__content{
    margin-top: 50px;
}
.termscondition__content h2{
    color: #000;
    text-align: left;
    font-family: 'Montserrat';
    font-size: 1rem;
    letter-spacing: normal;
    text-transform: none;
    line-height: 1.5rem;
    font-style: normal;
    font-weight: 400;
}
.termscondition__content ol{
    text-align: left;
    padding-left: 15px;
    font-size: 14px;
    font-family: 'Montserrat';
    line-height: 23px;
    color: #838383;
}
.termscondition__content p{
    font-family: 'Montserrat';
    font-size: 14px;
    font-weight: 500;
    line-height: 23px;
    text-align: left;
    color: #838383;
}

@media screen and (max-width: 550px) {
    .termscondition{
        padding: 120px 15px;
    }
}
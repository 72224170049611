.product_img {
  padding: 0px !important;
}

.productImage {
  margin: 30px 100px !important;
  object-fit: cover !important;
  width: 400px !important;
  height: 400px !important;
}

.btn_page_title2 {
  max-width: 1200px !important;
  margin: 0 auto;
  display: flex !important;
  align-items: center !important;
}

.page_title__heading {
  margin: 0px !important;
  padding: 0px !important;
  color: #6e4262 !important;
}

.fluid {
  padding-top: 10px !important;
  margin-top: 10px !important;
  /* border-top: 1px solid #666; */
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  font-family: Arial;
  line-height: 1.3;
  font-size: 16px;
  position: relative;
}

.fluid__instructions {
  flex: 0 0 auto;
  margin: 0 20px;
}

.fixed__instructions {
  flex: 1;
  margin: 0 20px;
}

a {
  color: black;
}

a:hover {
  color: #666;
}

.code {
  font-family: Courier New, Courier, monospace;
}

.fluid__image-container {
  position: relative;
}

.fluid__image-container__withoutAttrib {
  width: 720px;
  height: 720px;
}

.fluid__image-container__withAttrib {
  width: 500px;
  /* height: 500px; */
}

.fluid__image-container > div > img {
  object-fit: contain !important;
}

.itemDetail__ImageList {
  padding: 0px !important;
  /* margin-top: 15px; */
}

.selectedImg {
  opacity: 1 !important;
  cursor: default !important;
}

.not_selectedImg {
  cursor: pointer !important;
}

.fluid__arrowRight,
.fluid__arrowLeft {
  cursor: pointer;
}

.fluid__arrowRight {
  position: absolute;
  width: 40px;
  height: 40px;
  top: 45%;
  right: -30px;
  z-index: 10000;
}

.fluid__arrowLeft {
  position: absolute;
  width: 40px;
  height: 40px;
  top: 45%;
  left: -40px;
  z-index: 100000;
}

@media (min-width: 480px) {
  .fluid {
    flex-direction: row;
  }

  .fluid__image-container {
    flex: 0 0 30%;
    margin: 20px 0 20px 20px;
  }

  .fluid__instructions {
    flex: 0 0 50%;
    padding-top: 30px;
  }

  .fixed__instructions {
    padding-top: 20px;
    /* margin: 0 10px; */
  }

  .portal {
    position: absolute;
    top: 40px;
    left: -30px;
  }
}

.single_product_left {
  box-sizing: border-box;
  font-family: GraphikRegular;
  margin: 0;
  padding: 0;
}

@import url("https://fonts.googleapis.com/css2?family=Tenor+Sans&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;1,400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cormorant:ital,wght@0,400;0,600;1,300;1,400;1,500;1,600&family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;1,400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;1,100;1,300;1,400&display=swap");

@font-face {
  font-family: "Lato-Bold";
  src: url("fonts/Lato-Bold.ttf");
}

@font-face {
  font-family: "Lato-Light";
  src: url("fonts/Lato-Light.ttf");
}

@font-face {
  font-family: "Lato-Thin";
  src: url("fonts/Lato-Thin.ttf");
}

@font-face {
  font-family: "Lato-Regular";
  src: url("fonts/Lato-Regular.ttf");
}

:root {
  --main--primary-fontFamily-latoBold: Lato-Bold;
  --main--primary-fontFamily-latoLight: Lato-Light;
  --main--primary-fontFamily-latoRegular: Lato-Regular;
  --main--primary-fontFamily-latoThin: Lato-Thin;
}

body {
  margin: 0;
  /* font-family: "Lato", BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  font-family: var(--main--primary-fontFamily-latoRegular) !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  overflow-x: hidden;
}

.header-info {
  height: 75px;
  border: 1px solid transparent;
  background-color: #670f3b;
  text-align: unset !important;
  /* height: 30px; */
  font-family: Arial, Helvetica, sans-serif;
  font-size: 13px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 40px;
  color: #ffffff;
}

.header-info p {
  margin: 0;
  /* font-style: italic; */
  cursor: pointer;
}

.header-info__right {
  display: flex;
  position: relative;
  z-index: 999;
}

.header-info__right__cartData {
  position: absolute;
  width: 300px;
  height: 0px;
  top: 27px;
  right: 50%;
  border: 0;
  opacity: 0;
  transition: all 0.3s ease-in;
}

.header-info__right__shpCart:hover+.header-info__right__cartData,
.header-info__right__cartData:hover {
  height: 400px;
  border: 1px solid black;
  background-color: rgb(227, 220, 220);
  opacity: 1;
}

.header-info__right__wishListData {
  position: absolute;
  width: 300px;
  height: 0px;
  top: 27px;
  right: 60%;
  border: 0;
  opacity: 0;
  transition: all 0.3s ease-in;
}

.header-info__right__wishlst:hover+.header-info__right__wishListData,
.header-info__right__wishListData:hover {
  height: 400px;
  border: 1px solid black;
  background-color: rgb(227, 220, 220);
  opacity: 1;
}

.header__cartdata {
  height: 100%;
  overflow-y: auto;
  padding: 0px;
  z-index: 999;
}

.header-info__right__wishlst:hover+.header-info__right__wishListData .header__cartdata,
.header-info__right__wishListData:hover .header__cartdata {
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 15px;
  padding-bottom: 15px;
}

.header-info__right__shpCart:hover+.header-info__right__cartData .header__cartdata,
.header-info__right__cartData:hover .header__cartdata {
  padding: 30px;
}

.header__cartdata::-webkit-scrollbar {
  display: none;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.header__cartdata__cart {
  display: flex;
  margin: 10px 0;
}

.header__cartdata__cart p {
  /* font-family: "Tenor Sans"; */
  font-weight: 600;
}

.header__cartdata__cart p span {
  color: #838383;
}

.header__cartdata__total {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.header__cartdata__total p {
  /* font-family: "Montserrat"; */
  font-size: 14px;
  font-weight: 600;
  color: #000000;
}

.header__cartdata__btn {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.header__cartdata__btn button {
  width: 48%;
  height: 30px;
  border: 1px solid black;
  font-family: var(--main--primary-fontFamily-latoRegular);
  font-size: 10px;
  border-radius: 2px;
}

.user-login {
  position: relative;
}

.user-login__info {
  position: absolute;
  border: 1px solid #000000;
  background-color: rgb(227, 220, 220);
  width: 170px;
  top: 27px;
  right: 0;
  min-height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.header-info__mobNavBtn:hover {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}

.nav-main {
  height: 90px;
  z-index: -1;
}

.hov-abt:hover {
  text-decoration: underline;
}

.nav-font {
  font-size: 12px;
  font-weight: normal;
  letter-spacing: 2px;
  font-family: var(--main--primary-fontFamily-latoRegular);
  color: black;
}

.navbar {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.navbar ul {
  display: flex;
  align-items: center;
}

/* .navbar #navbarSupportedContent {
  display: flex;
  flex-direction: row;
} */
.navbar .mobileNavbarLinks {
  display: none;
}

.navbar .nav-item {
  text-align: start;
  padding: 11px 15px;
  font-family: var(--main--primary-fontFamily-latoRegular);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  /* cursor: pointer; */
}

.navbar .nav-item p {
  text-decoration: none;
  font-family: var(--main--primary-fontFamily-latoRegular);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 1px;
  cursor: pointer;
}

.navbar .nav-item p:hover {
  color: #670f3b;
  font-weight: 700;
}

.navbar ul {
  min-height: 20px;
}

nav::-webkit-scrollbar {
  height: 0px;
  width: 0px;
}

nav .scr::-webkit-scrollbar {
  height: 0px;
  width: 0px;
}

nav .scr::-webkit-scrollbar-track {
  height: 0px;
  width: 0px;
}

nav .scr::-webkit-scrollbar-thumb {
  height: 0px;
  width: 0px;
}

nav .scr::-webkit-scrollbar-thumb:hover {
  background: rgba(47, 95, 111, 1);
  height: 0px;
  width: 0px;
}

.navbar .nav-item:hover {
  border-bottom: 2px solid #670f3b;
  color: #670f3b !important;
  font-weight: 700;
}

.navbar .nav-item:after {
  border-bottom: 2px solid #670f3b;
  color: #670f3b !important;
  font-weight: 700;
}

.navbar__search {
  height: 79px;
  width: 170px;
  /* padding: 0 50px; */
  display: flex;
  align-items: center;
  justify-content: flex-start;
  /* gap: 100px; */
}

.navbar__search input {
  /* flex: 1; */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: rgba(255, 255, 255, 0.6);
  margin-left: 12px;
  text-align: left;
  outline: none;
  border: 0px;
  width: 100%;
  background-color: transparent;
}

.dropdown-toggle::after {
  transition: transform 0.15s linear;
}

.show.dropdown .dropdown-toggle::after {
  transform: translateY(3px);
}

.dropdown-menu {
  margin-top: 0;
}

.carousel-inner>.item {
  height: 600px;
}

.head-icon {
  list-style-type: none;
  display: inline-block;
}

.nav-i {
  font-size: 20px;
  font-weight: lighter;
}

.carousel-caption {
  margin-bottom: 150px;
  margin-left: -90px;
  text-align: right;
  width: 380px;
  font-family: "Tenor Sans", sans-serif;
}

.carousel_cap {
  font-size: 52px;
  letter-spacing: 2px;
  margin-right: 265px;
}

.carousel_para {
  font-size: 23px;
  font-family: Arial, Helvetica, sans-serif;
  width: 400px;
  text-align: left;
  margin-left: 30px;
  margin-top: 20px;
  font-weight: normal;
  letter-spacing: 1px;
}

.carosal-btn {
  background-color: white;
  height: 50px;
  width: 200px;
  border: none;
  font-size: 13px;
  margin-right: 300px;
  margin-top: 20px;
}

.speacial-occasion {
  margin: 80px;
  display: flex;
  justify-content: center;
  gap: 30px;
}

.special-occasion__content {
  position: relative;
  width: 535px;
  overflow: hidden;
  height: 450px;
}

.special-occasion__content__right {
  position: relative;
  width: 100%;
  height: 210px;
}

.special-occasion__content p {
  position: absolute;
  top: 20px;
  left: 40px;
  font-family: "TuesdayNight";
  font-size: 40px;
  color: #fff;
  font-weight: 100;
  opacity: 0.5;
  z-index: 2;
}

.special-occasion__content h3 {
  position: absolute;
  top: 60px;
  left: 40px;
  z-index: 3;
  font-family: "Tenor Sans";
  font-size: 23px;
  color: white;
  letter-spacing: 0.07em;
}

.special-occasion__content img {
  width: 110%;
  height: 100%;
  cursor: pointer;
  margin-left: -5%;
  transition: all 1s ease-in-out;
}

.special-occasion__content img:hover {
  transform: translate(4%, 0);
}

.diamond {
  margin: 80px;
  display: flex;
  justify-content: center;
  gap: 30px;
}

.diamond__content-left,
.diamond__content-right {
  width: 535px;
}

.diamond__content-left img {
  max-width: 100%;
}

.diamond__content-right {
  display: flex;
  flex-wrap: wrap;
  padding: 10px 20px;
}

.diamond__content-right__card {
  margin: 0px 1%;
  width: 48%;
  height: auto;
  position: relative;
  cursor: pointer;
  margin-top: -8px;
}

.diamond__content-right__card img {
  max-width: 249px;
  transition: all 0.5s ease-in-out;
}

.diamond__content-right__card .name {
  font-size: 13px;
  letter-spacing: 0.1rem;
  font-weight: 400;
  margin-bottom: 2px;
  transition: color 0.2s ease-out;
}

.diamond__content-right__card .price {
  font-size: 16px;
  letter-spacing: 0.05rem;
  color: #a2a2a2;
  font-family: "Montserrat";
  font-weight: 500;
}

.diamond__content-right__card__btn {
  opacity: 0;
  transition: all 0.5s ease-in-out;
}

.diamond__content-right__card:hover .diamond__content-right__card__btn {
  opacity: 1;
}

.diamond__content-right__card:hover img {
  opacity: 0;
}

.diamond__content-right__card__btn {
  position: absolute;
  top: 30%;
  left: 80%;
  transform: translate(-50%, 0);
  width: 100%;
}

@media screen and (min-width: 400px) and (max-width: 1200px) {
  .carousel_cap {
    margin-bottom: 0px;
    font-size: 20px;
    margin-left: 25px;
    margin-top: -400px;
  }

  .carousel_para {
    margin-left: 20px;
  }

  .carousel-caption {
    font-size: 15px;
    text-align: left;
  }

  .carosal-btn {
    margin-left: 20px;
  }

  .ocasion-div {
    margin-top: -300px;
    margin-left: 0px;
  }

  .ocasion-div .grid-col1 {
    margin-left: -16px;
    position: relative;
  }

  .ocasion-div .grid-col2 {
    margin-top: 20px;
    position: relative !important;
  }

  .grid-call-3 .watermark-3 {
    margin-top: 30px;
  }

  .cart-div .sec-row-earing {
    margin-top: 260px;
    height: 500px;
    width: 100%;
    justify-content: center;
    margin-left: -50px;
  }

  .logo-group .logo-row {
    margin-left: 100px;
  }
}

.lineUp {
  animation: 2s anim-lineUp ease-out;
}

@keyframes anim-lineUp {
  0% {
    opacity: 0;
    transform: translateX(80%);
  }

  20% {
    opacity: 0;
  }

  50% {
    opacity: 1;
    transform: translateX(0%);
  }

  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}

.lineUp2 {
  animation: 3s anim-lineUp ease-out;
}

@keyframes anim-lineUp {
  0% {
    opacity: 0;
    transform: translateX(80%);
  }

  20% {
    opacity: 0;
  }

  50% {
    opacity: 1;
    transform: translateX(0%);
  }

  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}

.lineUp3 {
  animation: 4s anim-lineUp ease-out;
}

@keyframes anim-lineUp {
  0% {
    opacity: 0;
    transform: translateX(80%);
  }

  20% {
    opacity: 0;
  }

  50% {
    opacity: 1;
    transform: translateX(0%);
  }

  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}

.grid-col1 {
  background-image: url(./image/earing-grid1.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 450px;
  width: 400px;
  transition-timing-function: ease ease-in-out;
  transition-duration: 2s;
}

.grid-col1:hover {
  background-position: right -28px center;
}

.grid-col2 {
  background-image: url(./image/earing-grid2.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 210px;
  transition-timing-function: ease ease-in-out;
  transition-duration: 2s;
}

.grid-col2:hover {
  background-position: right -8px center;
}

.grid-col3 {
  background-image: url(./image/earing-grid3.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 210px;
  transition-timing-function: ease ease-in-out;
  transition-duration: 2s;
}

.grid-col3:hover {
  background-position: right -8px center;
}

.ocasion-div__special {
  font-size: 40px;
  font-family: "TuesdayNight";
  color: grey;
  position: absolute;
  top: 30px;
  left: 250px;
}

.watermark-1 {
  color: white;
  font-weight: normal;
  font-family: "Tenor Sans", sans-serif;
  text-align: left;
  font-size: 22px;
  letter-spacing: 1px;
  margin-top: 55px;
  margin-left: 35px;
  z-index: 99;
}

.earing2 {
  background-image: url(./image/earing-row2.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 660px;
}

@media screen and (min-width: 400px) and (max-width: 1200px) {
  .earing2 {
    display: block;
    width: 600px;
    margin-left: -30px;
    margin-right: 100px;
  }

  .add-to-cart-item {
    display: inline-block;
    margin-left: -60px;
  }

  .logo-col {
    margin-left: -70px;
  }

  .main-text-img {
    margin-left: -60px;
    width: 700px;
  }

  .ocasion-div {
    margin-top: -450px;
  }

  .lineUp3 {
    margin-top: -80px;
    height: 30px;
    font-size: 12px;
    width: 200px;
  }
}

.overlay {
  display: none;
}

.card-style:hover .overlay {
  display: block;
}

.card-style:hover img {
  cursor: pointer;
  opacity: 0;
}

.style {
  width: 288px;
}

.text {
  color: black;
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}

.image {
  display: block;
  width: 100%;
  height: auto;
}

.card-style:hover .overlay {
  opacity: 1;
}

.add-btn {
  width: 44px;
  height: 44px;
  border: 1px solid gray;
  background-color: white;
}

.add-btn i {
  font-size: 14px;
  font-weight: lighter;
}

.sec-row-earing {
  height: 750px;
  margin-left: 50px;
}

.main-text-img {
  background-color: rgb(252, 248, 246);
}

.innerdiv-text {
  margin-top: 120px;
  margin-left: 60px;
  width: 520px;
  text-align: left;
}

.inner-heading {
  font-size: 2rem;
  font-family: "Tenor Sans";
  text-transform: capitalize;
}

.div-para {
  margin-top: 30px;
  font-size: 17px;
  color: rgb(122, 134, 111);
  font-family: "Montserrat";
  width: 470px;
}

.shop-row-text {
  font-family: "Tenor Sans", sans-serif;
  font-size: 14px;
  padding-top: 10px;
}

.shop-head {
  margin-top: 16px;
  font-family: Tenor Sans;
  font-size: 0.875rem;
  font-style: normal;
  text-transform: uppercase;
}

.logo-img {
  margin: auto;
}

.logo-col {
  margin-right: 0px;
  border: none;
  background-color: transparent;
}

.main-logo-div {
  padding-bottom: 100px;
  padding-top: 100px;
  background-color: rgb(252, 248, 246);
}

.card-body-text {
  margin-top: 10px;
}

.heading-text {
  font-size: 16px;
  letter-spacing: 3px;
  font-weight: normal;
}

.card-para-text {
  font-size: 0.875rem;
  color: #000;
  font-family: "MontSerrat";
  font-weight: 100;
  font-style: normal;
}

.footer-main {
  padding-bottom: 35px;
  padding-top: 35px;
  background-color: #670f3b;
  font-family: "Poppins";
  color: #ffffff;
}

.footer-main p {
  cursor: pointer;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  color: #ffffff;
}

.whatsApp {
  position: fixed;
  right: 30px;
  bottom: 20px;
  cursor: pointer;
  z-index: 999;
}

.footer-add1 {
  line-height: 6px;
  padding-bottom: 20px;
  font-size: 14px;
}

.footer-add2 {
  padding-bottom: 20px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  color: #ffffff;
}

.footer-add3 {
  padding-bottom: 20px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  color: #ffffff;
}

.footer-main a {
  color: #ffffff;
  text-decoration: none;
  margin-bottom: 16px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
}

.footer-main a:hover {
  color: #fff;
}

.FAQs {
  padding: 120px;
}

.FAQs h1 {
  font-family: "Tenor Sans";
  font-size: 2rem;
  line-height: 1.625rem;
  font-style: normal;
}

.FAQs h3 {
  font-family: "Tenor Sans";
  font-size: 1.3125rem;
  line-height: 1.625rem;
  font-style: normal;
  text-align: left;
  margin-top: 50px;
}

.FAQs p {
  white-space: pre-line;
  font-family: "Montserrat";
  font-size: 1rem;
  line-height: 1.5rem;
  font-style: normal;
  text-align: left;
}

.privacyPolicy {
  padding: 50px 120px;
}

.privacyPolicy h3 {
  font-family: "Tenor Sans";
  font-size: 1.25rem;
  line-height: 1.625rem;
  font-style: normal;
  text-align: left;
  margin-bottom: 30px;
  font-weight: bold;
}

.privacyPolicy p {
  white-space: pre-line;
  font-family: "Montserrat";
  font-size: 1rem;
  line-height: 1.5rem;
  font-style: normal;
  text-align: left;
  font-weight: 100;
  margin-bottom: 30px;
}

.social-media {
  color: black;
}

/* .social-media:hover, .social-media:active{
  color: black !important;
} */

.jwel-guides {
  list-style-type: none;
  line-height: 13px;
  font-size: 14px;
}

.jwel-policis {
  list-style-type: none;
  line-height: 13px;
  font-size: 14px;
}

.mega-menu {
  display: none;
  /* margin-left: -670px; */
  background-color: #f6f6f6;
  margin-top: 12px;
  /* width: 1800px; */
  /* height: 430px; */
  position: relative;
  z-index: 9999;
}

.mega-menu {
  /* width: 2000px; */
  left: 0%;
  right: 0%;
}

.mega-menu-2 {
  display: none;
  margin-left: -30px;
  margin-top: 12.5px;
  background-color: #f6f6f6;
  width: 200px;
  height: 120px;
  position: relative;
  z-index: 9999;
}

.mega-menu-3 {
  display: none;
  margin-left: 0px;
  margin-top: 12.5px;
  background-color: #f6f6f6;
  width: 100vw;
  left: 0%;
  right: 0%;
  height: 430px;
  position: relative;
  z-index: 9999;
}

.mega-list {
  list-style-type: none;
  text-align: left;
  line-height: 15px;
  font-size: 15px;
  margin-top: 12.5px;
  letter-spacing: normal;
  /* font-family: "Tenor Sans", sans-serif; */
  color: rgb(118, 126, 111);
}

.mega-list p {
  cursor: pointer;
}

.dropdown:hover .mega-menu {
  display: block;
  position: absolute;
}

.dropdown:hover .mega-menu-2 {
  display: block;
  position: absolute;
  background-color: #f6f6f6;
}

.dropdown:hover .mega-menu-3 {
  display: block;
  position: absolute;
  background-color: #f6f6f6;
}

#input-footer {
  height: 36px;
  width: 270px;
}

.foot-icon {
  font-size: 27px;
  margin-left: 10px;
}

.foot-icon:hover {
  color: white;
}

#foot-sub {
  height: 36px;
  font-size: 13px;
  width: 120px;
  background-color: rgb(73, 72, 72);
  color: white;
}

#foot-sub:hover {
  background-color: rgb(37, 37, 37);
}

/* ABOUT US PAGE STYLEING STARTS HERE */

.about-text-row {
  margin-top: 80px;
  font-family: "Tenor Sans", sans-serif;
}

.inclusive-text {
  font-family: "Tenor Sans";
  color: black;
  letter-spacing: 0.05em;
}

.inclusive-para {
  color: #000000;
  font-size: 16px;
  font-family: "Montserrat";
  margin: 12px auto !important;
  font-weight: 100;
  width: auto;
  max-width: 630px;
}

.first-hr-line {
  margin-top: 60px;
  color: gray;
}

.guiding-div {
  margin-top: 60px;
}

.heading-guide {
  width: 3auto;
  font-size: 32px;
  color: black;
  font-family: "Tenor Sans", sans-serif;
}

.guiding-para {
  width: auto;
  font-size: 16.5px;
  text-align: left;
  font-family: "Tenor Sans", sans-serif;
  color: rgb(122, 134, 111);
  margin-top: 20px;
}

.heading-guide-2 {
  width: auto;
  font-size: 32px;
  color: black;
  font-family: "Tenor Sans", sans-serif;
}

.guiding-para-2 {
  width: auto;
  font-size: 16.5px;
  text-align: left;
  font-family: "Tenor Sans", sans-serif;
  color: rgb(122, 134, 111);
  margin-top: 20px;
}

/* SHOP PAGE STYLEING STARTS HERE */

.slidecontainer {
  width: 100%;
}

.slider {
  -webkit-appearance: none;
  width: 200px;
  height: 5px;
  background: rgb(247, 194, 184);

  outline: none;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

.slider:hover {
  opacity: 1;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 10px;
  height: 10px;
  background: black;
  border-radius: 100%;
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  background: black;
  cursor: pointer;
}

/* COLLECTIONS PAGE STARTS HERE */

.carouselConatiner {
  margin: 80px 80px 0;
}

.react-multiple-carousel__arrow {
  background-color: transparent !important;
  z-index: 0 !important;
}

.react-multiple-carousel__arrow:hover {
  background-color: #4e4e4e !important;
}

.rosetta-col {
  background-image: url(./image/rosetta.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 420px;
}

.rosetta-text-box {
  width: auto;
  margin-left: 80px;
  /* margin-top: 160px; */
  text-align: left;
  padding-right: 120px;
}

.rosetta-heading {
  font-family: "Tenor Sans", sans-serif;
  font-size: 32px;
  color: black;
  letter-spacing: 3px;
}

.rosetta-para {
  font-size: 16px;
  font-family: "Montserrat";
  color: #000000;
  margin-top: 19px;
  font-weight: 100;
}

.ros-caro {
  margin-top: 50px;
  height: 530px;
}

.image-ros-div {
  height: 350px;
}

.ros-shp-btn {
  height: 55px;
  width: 180px;
  font-size: 14px;
  border: 1px solid black;
  color: black;
  margin-top: 40px;
  background-color: white;
}

.ros-shp-btn:hover {
  border: none;
  background-color: rgb(227, 220, 220);
}

.shop-btn:hover {
  border: none;
  background-color: rgb(227, 220, 220);
}

.shop-btn {
  height: 55px;
  width: 180px;
  font-size: 14px;
  border: 1px solid black;
  color: black;
  margin-top: 40px;
  background-color: white;
}

.ragini-col {
  background-image: url(./image/ragini-bangle.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 420px;
}

.ragini-shop-btn {
  height: 55px;
  width: 180px;
  font-size: 14px;
  border: 1px solid black;
  color: black;
  margin-top: 40px;
  background-color: white;
}

.anant-col {
  background-image: url(./image/anant.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 420px;
}

.modena-col {
  background-image: url(./image/modena-main.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 420px;
}

.modena-shop-btn {
  height: 55px;
  width: 180px;
  font-size: 14px;
  border: 1px solid black;
  color: black;
  margin-top: 0px;
  background-color: white;
}

.col-xs-4 {
  width: 33.33%;
}

/* COINS PAGE STYLEING STARTS HERE */

.coins-align {
  margin-left: 100px;
  padding-bottom: 100px;
  padding-top: 50px;
}

.feed-input {
  height: 100px;
  width: 850px;
}

/* SHOP-AAINA STARTS HERE */

.shop-aaina-container {
  width: 100%;
  height: 600px;
  background-color: rgb(124, 168, 122);
}

.bird-div {
  margin-top: 120px;
  color: white;
}

.bird-h2 {
  font-family: "Tenor Sans", sans-serif;
  margin-top: 20px;
  letter-spacing: 2px;
  font-weight: 400;
  font-size: 1.3rem;
}

.bird-para {
  font-size: 16px;
  margin-top: 20px;
  word-spacing: 1px;
  font-family: "Montserrat";
  width: auto;
  max-width: 842px;
  font-weight: lighter;
  /* margin-left:30px; */
}

.bird-btn {
  border: 1px solid white;
  width: 200px;
  height: 50px;
  background-color: transparent;
  margin-top: 40px;
  color: white;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 2px;
}

.aaina-shop-btn {
  width: 180px;
  height: 40px;
  background-color: rgb(136, 162, 123);
  border: 1px solid rgb(136, 162, 123);
  color: white;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 2px;
  margin-top: 40px;
}

.form-range {
  width: 50%;
}

.mobile__filter {
  display: none;
}

.filter-list__div,
.mobile__filter__img__div {
  max-height: calc(100vh - 95px);
  overflow: scroll;
}

.filter-list__div::-webkit-scrollbar,
.mobile__filter__img__div::-webkit-scrollbar {
  display: none;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.filter__card__div {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

#multi img {
  /* margin-top: 20px;
  margin-right: 20px; */
  height: 260px;
  width: 100%;
  background-color: transparent;
  cursor: pointer;
}

.pagination-design {
  border: 1px solid white;
}

#multi {
  position: relative;
  width: 30%;
}

.cart-btn {
  position: absolute;
  /* margin-top: -200px;
  margin-left:90px; */
  top: 30%;
  left: 50%;
  transform: translate(-50%, 0);
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.5s linear;
}

#multi:hover .cart-btn {
  visibility: visible;
  opacity: 1;
}

.btn-1 {
  margin-right: 10px;
  border: 1px solid rgb(175, 174, 174);
  transition: all 0.3s ease-in;
}

.btn-2 {
  border: 1px solid rgb(175, 174, 174);
  transition: all 0.3s ease-in;
}

.btn-1:hover,
.btn-2:hover {
  background-color: black;
  border: 1px solid black;
}

.btn-1:hover .fa-plus,
.btn-1:hover .fa-minus,
.btn-2:hover .fa-heart-broken,
.btn-2:hover .fa-heart {
  color: white;
}

/* Shop product */

.productContainer__product {
  padding-right: 100px;
  padding-left: 100px;
  padding-top: 50px;
  padding-bottom: 50px;
  display: flex;
  justify-content: space-around;
}

.productContainer__product img {
  flex: 1;
  /* max-width: 424px;
  max-height: 424px; */
  /* border: "1px solid #d7d7d7"; */
}

.productContainer__product__details {
  flex: 1;
  max-width: 50%;
  padding-left: 70px;
  text-align: start;
  font-family: "Tenor Sans", sans-serif;
}

.productContainer__product__details h1 {
  font-size: 32px;
  font-weight: 400;
}

.productContainer__product__details h5 {
  font-family: "Montserrat";
  font-size: 25px;
  font-weight: 500;
  margin: 20px 0 0 0;
  color: #aa4762;
}

.productContainer__product__details__sold {
  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 600;
  margin: 18px 0 0 0;
  color: #ff0000;
}

.productContainer__product__details button {
  font-family: "Montserrat";
  width: 200px;
  height: 52px;
  border: 1px solid black;
  margin: 54px 0 54px 20px;
  background-color: #b87d8b;
  color: white;
  font-size: 12px;
  letter-spacing: 2px;
  transition: all 0.2s ease-in;
}

.productContainer__product__details button:hover {
  background-color: white;
  color: black;
}

.productContainer__product__details__fav {
  font-family: "Montserrat";
  color: #b87d8b;
  cursor: pointer;
  font-size: 18px;
}

.productContainer__product__details__fav:hover {
  color: #838383;
}

.productContainer__product__details__info {
  font-family: "Lato,sans-serif";
  letter-spacing: 4px;
  font-size: 12px;
  line-height: 24px;
  margin: 1px 0;
}

.productContainer__additionalInfo {
  padding: 0 100px 100px;
  text-align: left;
}

.productContainer__additionalInfo button {
  border: 1px solid #d7d7d7;
  font-family: "Cormorant", "serif";
  font-size: 20px;
  font-style: italic;
  color: #5a5a5a;
  padding: 12px 59px 16px;
  background-color: transparent;
  margin: 0 5px 30px;
  transition: all 0.3s ease-in-out;
}

.productContainer__additionalInfo button:hover {
  border: 1px solid #000;
}

.productContainer__tabs__info__content {
  display: flex;
}

.productContainer__tabs__info__content p {
  margin: 0 10px;
  color: #838383;
  min-height: 30px;
}

.content__head {
  width: 150px;
  max-width: 150px;
  margin: 0 0 0 5px;
  font-family: "Lato", "sans-serif";
  color: #000 !important;
  font-weight: 400;
  font-size: 12px;
  line-height: 23px;
  letter-spacing: 0.075em;
}

.star-rating button {
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  font-style: normal;
  border: 0;
  padding: 0;
  margin: 0;
}

.star-rating button:hover {
  border: 0;
}

.star-rating .on {
  color: #000;
}

.star-rating .off {
  color: #ccc;
}

.productContainer__tabs__review button {
  margin: 10px 0 0;
  font-family: "Lato", "sans-serif";
  font-weight: 400;
  font-style: normal;
  line-height: 2em;
  letter-spacing: 0.2em;
  outline: 0;
}

.productContainer__tabs__review__submit {
  font-size: 12px !important;
  padding: 13px 48px !important;
  background-color: #000 !important;
  color: white !important;
}

.productContainer__tabs__review__submit:hover {
  color: #000 !important;
  background-color: white !important;
}

.review-textarea__review {
  width: 100%;
  margin: 5px 0;
  font-family: "Cormorant", "serif";
  padding: 12px 16px;
  font-size: 18px;
  font-weight: 500;
  font-style: italic;
  color: #5a5a5a;
  background-color: transparent;
  border: 1px solid #cbcbcb;
  border-radius: 0;
  text-align: left;
  line-height: 26px;
  outline: 0;
}

.list-filter-head a {
  list-style-type: none;
  text-decoration: none;
  color: black;
  font-family: "Tenor Sans", sans-serif;
  font-size: 18px;
  letter-spacing: 3px;
}

.filter-data-list a {
  list-style-type: none;
  text-decoration: none;
  color: gray;
  font-family: "Tenor Sans", sans-serif;
  font-size: 16px;
  font-weight: bold;
}

.price_desc {
  font-weight: bold;
  font-family: "Tenor Sans", sans-serif;
  letter-spacing: 1px;
}

.price_desc2 {
  font-weight: bold;
  font-family: "Tenor Sans", sans-serif;
  letter-spacing: 1px;
  line-height: 1px;
  color: gray;
}

.range .range-slider {
  width: 200px;
  display: block;
  color: rgb(247, 208, 201);
  height: 2px;
}

.range-price {
  margin-top: 10px;
  font-size: 20px;
  font-family: "Tenor Sans", sans-serif;
}

.necklace-div {
  background-image: url(./image/neck-1.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  height: 600px;
}

.necklace-div2 {
  background-image: url(./image/neck-2.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  height: 600px;
}

.add-to-cart-bg-img {
  background-image: url(./image/caro1.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: initial;
  height: 240px;
}

span {
  cursor: pointer;
}

.minus,
.plus {
  width: 40px;
  height: 34px;
  border: 1px solid black;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
}

input {
  height: 34px;
  width: 100px;
  text-align: center;
  font-size: 50px;
  border: 1px solid #ddd;
  border-radius: 4px;
  display: inline-block;
  vertical-align: middle;
}

.aaina {
  background-image: url(./image/aaina_authentic.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 400px;
}

.aaina-bg {
  background-color: rgb(247, 247, 247);
}

.aaina-auth-decs {
  margin-top: 80px;
  width: 500px;
  text-align: left;
  margin-left: 60px;
}

.aaina-auth-heading {
  font-size: 21px;
  /* letter-spacing: 2px; */
  font-family: "Tenor Sans", sans-serif;
  /* font-weight: bold; */
}

.aaina-auth-para {
  font-size: 16px;
  margin-top: 20px;
  font-family: "Montserrat";
  font-weight: 100;
}

.aaina-book-btn {
  font-size: 12px;
  padding: 15px;
  width: 320px;
  height: 50px;
  letter-spacing: 2px;
  background-color: white;
  border: 1px solid black;
}

.aaina-book-btn:hover {
  background-color: black;
  color: white;
}

.already_login {
  margin-top: 20px;
  margin-bottom: 30px;
}

#uname {
  width: 450px;
  height: 70px;
  text-align: left;
  margin-bottom: 20px;
  border-radius: none;
  font-size: 20px;
  padding-left: 15px;
  font-style: italic;
  font-family: "Tenor Sans", sans-serif;
  color: black;
  margin-left: 50px;
}

#psw {
  width: 450px;
  height: 70px;
  text-align: left;
  border-radius: none;
  font-size: 20px;
  padding-left: 15px;
  font-style: italic;
  font-family: "Tenor Sans", sans-serif;
  color: black;
  margin-left: 50px;
}

.log_in-btn {
  height: 45px;
  width: 145px;
  background-color: white;
  border: 1px solid black;
  font-size: 14px;
}

.log_in-btn:hover {
  background-color: black;
  color: white;
}

.login_div {
  margin-left: 0px;
  margin-top: 15px;
  display: flex;
  align-items: center;
}

.forgot_passoword {
  text-align: left;
  font-size: 22px;
  font-family: "Montserrat";
  line-height: 25px;
  font-weight: 500;
  font-style: italic;
  margin: 15px 0 25px;
  cursor: pointer;
}

.create_acc_btn {
  width: 350px;
  height: 50px;
  font-size: 12px;
  letter-spacing: 2px;
  background-color: rgb(241, 218, 214);
  border: none;
  margin-bottom: 40px;
  margin-left: 0px;
  transition: all 0.3s ease-in-out;
}

.create_acc_btn:hover {
  color: #fff;
  background-color: #000000;
}

.title {
  font-size: 1rem;
  color: #000000;
  text-align: center;
  font-family: "Montserrat";
  line-height: 1.5rem;
  font-weight: 100;
}

.title_log_in {
  font-family: "Tenor Sans", sans-serif;
  font-size: 40px;
  margin-bottom: 50px;
}

.modal-header {
  border: 0;
}

.modal-border {
  background-color: white;
}

.MuiDialog-paperScrollPaper {
  max-height: none;
}

.ezee_buy_page {
  width: 100%;
}

.ezee_buy_page img {
  max-width: 100%;
}

.ezee-plan-div {
  margin: 80px auto;
}

.ezee-plan-div img {
  padding: 0 15px;
  max-width: 100%;
}

.plan_11 {
  background-image: url(./image/ezee_buy_plan_11.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 305px;
  width: 601px;
}

.plan_18 {
  background-image: url(./image/ezee_buy_plan_18.jpg);
  background-repeat: no-repeat;
  height: 350px;
  /* margin-left: 20px; */
}

.nav-anchor-list {
  text-decoration: none;
  color: #222222;
}

nav {
  background: #ffffff !important;
  box-shadow: 2px 2px 4px rgba(136, 136, 136, 0.08) !important;
}

.btn-cart-coupon {
  width: 200px;
  height: 50px;
  font-size: 16px;
  letter-spacing: 2px;
}

.btn-cart-appcoupon {
  width: 200px;
  height: 50px;
  font-size: 12px;
  letter-spacing: 2px;
  background-color: rgb(184, 125, 139);
  color: white;
  border: 1px solid black;
  margin-left: 60px;
}

.update-cart {
  width: 200px;
  height: 50px;
  font-size: 12px;
  letter-spacing: 2px;
  background-color: rgb(184, 125, 139);
  color: white;
  border: 1px solid black;
  margin-left: 220px;
}

.proceed-btn {
  width: 250px;
  height: 55px;
  font-size: 12px;
  letter-spacing: 2px;
  background-color: black;
  color: white;
  border: 1px solid black;
  margin-left: -10px;
}

.sub-menu-parent {
  position: relative;
}

.sub-menu {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  margin-top: 50px;
  left: 0;
  width: 100%;
  transform: translateY(-2em);
  z-index: 1;
  transition: all 0.3s ease-in-out 0s, visibility 0s linear 0.3s,
    z-index 0s linear 0.01s;
}

.header_cart_menu {
  border: 2px solid rebeccapurple;
  z-index: 999;
}

.sub-menu-parent:focus .sub-menu,
.sub-menu-parent:focus-within .sub-menu,
.sub-menu-parent:hover .sub-menu {
  visibility: visible;
  opacity: 1;
  z-index: 1;
  transform: translateY(0%);
  transition-delay: 0s, 0s, 0.3s;
}

.login-accord-text {
  color: rgb(163, 150, 149);
  padding-top: 20px;
  font-weight: normal;
}

.checkout-login {
  height: 53px;
  width: 160px;
  border: 1px solid black;
  background-color: rgb(184, 125, 139);
  color: white;
  font-size: 14px;
  letter-spacing: 2px;
  margin-left: 10px;
}

.user-login-div {
  height: 60px;
}

.form-control {
  border-radius: none;
}

.login-label {
  font-size: 18px;
  color: gray;
  font-family: "Tenor Sans", sans-serif;
}

.coup-btn {
  height: 53px;
  width: 230px;
  border: 1px solid black;
  background-color: rgb(184, 125, 139);
  color: white;
  font-size: 12px;
  letter-spacing: 2px;
  margin-top: 20px;
}

.regis-input-login {
  height: 50px;
  text-align: start;
  font-size: 11px;
}

.form__control {
  height: 50px;
  width: 350px;
  text-align: start;
}

.loggin__button {
  height: 45px;
  width: 120px;
  /* margin-top: 20px; */
}

.forget {
  margin-top: 20px;
}

.forget__password {
  text-decoration: none;
  color: black;
  font-size: 20px;
  cursor: pointer;
}

.register-dialog {
  padding: 22px 33px 22px !important;
  /* max-width: 470px; */
}

.register-dialog::-webkit-scrollbar {
  display: none;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.register {
  height: 100%;
  padding: 40;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.register h2 {
  font-family: "Tenor Sans";
  font-size: 32px;
  letter-spacing: 0.05em;
  text-align: center;
}

.register__inputs {
  width: 100%;
  /* max-width: 430px; */
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  padding: 12px 0px;
}

.register input,
.register select {
  /* width: 100%; */
  min-width: 270px;
  height: 40px;
  text-align: left;
  padding: 0px 23px;
  font-family: "Cormorant";
  font-weight: 500;
  font-style: italic;
  font-size: 18px;
  line-height: 19px;
  background-color: #fff;
  border: 1px solid #cbcbcb;
  border-radius: 2px;
  /* margin-bottom: 10px; */
}

.register label {
  font-family: "Tenor Sans", sans-serif;
  color: #6b6868;
}

datalist {
  position: absolute !important;
  background-color: white;
  border: 1px solid blue;
  border-radius: 0 0 5px 5px;
  border-top: none;
  width: 350px;
  padding: 5px;
  max-height: 200px;
  overflow-y: auto;
}

.register input:focus,
.register select:focus {
  color: #212529;
  background-color: #fff;
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

.register__btn {
  width: 270px;
  border: 1px solid #000000;
  height: 40px;
  background-color: #fff;
  font-size: 12px;
  font-weight: 400;
  font-family: "Lato", sans-serif;
  letter-spacing: 0.2em;
  transition: all 0.3s ease-in-out;
}

.register__btn:hover {
  color: #fff;
  background-color: #000000 !important;
}

.register p {
  margin-top: 10px;
  font-size: 18px;
  font-family: "Cormorant";
  font-weight: 600;
  font-style: italic;
  letter-spacing: 0.07em;
}

.register p:hover {
  color: #aa4762;
  cursor: pointer;
}

.tableContainer {
  padding: 80px 100px;
}

.table th {
  text-align: left;
  padding-left: 0 !important;
  font-weight: 400;
  border-bottom: none !important;
}

.table> :not(:first-child) {
  border-top: 1px solid lightgray !important;
}

.tableRow td {
  padding: 25px 0;
  text-align: left;
  vertical-align: middle !important;
}

.order-table-data {
  border-bottom: 1px solid rgb(161, 160, 160);
  text-align: start;
  padding-bottom: 10px;
  padding-top: 10px;
}

.table-width {
  width: 100%;
}

.razorpay-logo {
  position: absolute;
  top: -25px;
  left: 255px;
}

.place-order {
  height: 50px;
  width: 200px;
  border: 1px solid black;
  background-color: rgb(184, 125, 139);
  color: white;
  font-size: 13px;
  letter-spacing: 2px;
}

.place-order:hover {
  background-color: white;
  color: black;
}

.pay-now {
  height: 40px;
  width: 200px;
  border: 1px solid black;
  background-color: white;
}

.cancel {
  height: 40px;
  width: 200px;
  border: 1px solid black;
  background-color: white;
  margin-left: 50px;
}

.pay-now:hover {
  background-color: black;
  color: white;
}

.cancel:hover {
  background-color: black;
  color: white;
}

.selected-item-box {
  border: 1px solid gray;
  height: 90px;
  width: 800px;
  padding-left: 20px;
  padding-top: 30px;
}

.billing_input {
  height: 55px;
}

.label-styling {
  font-size: 17px;
  font-family: "Tenor Sans", sans-serif;
  color: #6b6868;
}

.billing_heading {
  font-family: "Tenor Sans", sans-serif;
  font-size: 22px;
}

/* Css for mobile device */

@media screen and (max-width: 550px) {
  .navbar {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .navbar ul {
    display: none;
  }

  .navbar .mobileNavbarLinks {
    display: block;
    min-height: 550px;
    max-height: 550px;
    border-bottom: 1px solid #cbcbcb;
    overflow: auto;
  }

  .carousel-inner>.item img {
    height: 100%;
  }

  .carousel>button span {
    visibility: hidden;
  }

  .carousel-caption {
    left: 25% !important;
  }

  .carousel_para {
    display: none;
  }

  .speacial-occasion {
    flex-direction: column;
    margin: 0px 10px;
  }

  .special-occasion__content {
    width: 100%;
  }

  .diamond {
    flex-direction: column;
    margin: 0;
  }

  .diamond__content-left {
    display: none;
  }

  .diamond__content-right {
    width: 100%;
  }

  .diamond__content-right__card img {
    max-width: 100%;
  }

  .necklace-div,
  .necklace-div2 {
    height: 300px;
    max-width: 100%;
  }

  .main-text-img {
    margin-left: 0;
  }

  .mainhome__necklace-div2 {
    display: flex;
    flex-direction: column-reverse;
  }

  .innerdiv-text {
    margin-left: 0;
    padding: 12px;
    margin-bottom: 120px;
    width: auto;
  }

  .div-para {
    width: auto;
  }

  .form__control,
  .create_acc_btn {
    width: 100%;
  }

  .register input {
    min-width: 100%;
  }

  .rosetta-text-box {
    margin-top: 80px;
    margin-left: 35px;
    padding-right: 0;
    margin-bottom: 80px;
  }

  .carouselConatiner {
    margin: 80px 0;
  }

  .tableContainer {
    padding: 80px 10px;
  }

  .table__img {
    display: none;
  }

  .table td:nth-child(3),
  .table th:nth-child(3) {
    max-width: 75px !important;
  }

  .table td,
  .table th {
    padding: 10px 0 10px 10px !important;
  }

  .table td:nth-child(n + 4),
  .table th:nth-child(n + 4) {
    text-align: right;
  }

  .card__coupon {
    flex-direction: column !important;
    gap: 10px;
  }

  .coupon__div {
    width: 100% !important;
  }

  .btn-cart-coupon {
    width: 100%;
  }

  .btn-cart-appcoupon {
    width: 100%;
    margin: 0;
    margin-top: 10px;
  }

  .update-cart__div {
    width: 100% !important;
  }

  .update-cart {
    width: 100%;
    margin: 0;
  }

  .proceed-btn {
    width: 100%;
    margin-left: 0;
  }

  .FAQs {
    padding: 120px 15px;
  }

  .privacyPolicy {
    padding: 30px;
  }

  /* Shop Jewellery UI for mobile*/
  .filter__div,
  .filter-list__div {
    display: none !important;
  }

  .mobile__filter__img__div {
    width: 100% !important;
    max-height: none;
  }

  .mobile__filter {
    display: block;
    width: 100% !important;
    z-index: 99;
    position: fixed;
    background-color: #000;
    color: #fff;
    bottom: 0;
    padding: 0 !important;
  }

  .mobile__filter button {
    background-color: #000;
    border: 0px;
    color: #fff;
    width: 50%;
    padding: 13px 48px;
    font-family: "Lato", sans-serif;
    font-size: 12px;
    letter-spacing: 0.2em;
  }

  .mobile__filter button:active {
    background-color: #4e4e4e;
  }

  .mobile__filter__box {
    width: 275px;
    padding: 20px;
  }

  .mobile__filter__box__range__slider {
    width: 100% !important;
    color: rgb(247, 208, 201) !important;
    height: 2px !important;
  }

  .mobile__filter__box__price {
    font-family: "Montserrat";
    font-size: 14px;
    font-weight: 500;
    color: #838383;
  }

  .filter__card__div {
    justify-content: center;
    margin-top: 50px;
  }

  #multi {
    width: 40%;
  }

  #multi img {
    width: 100%;
    height: 75%;
  }

  .cart-btn {
    left: 50%;
    transform: translate(-50%, 0);
    margin: 0px 0px 0px 0px;
  }

  .add-btn {
    width: 30px;
    height: 30px;
  }
}

.typoChild {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  /* identical to box height */
  margin: 8px;

  color: #000000;
}

.editProfile {
  height: 100%;
  padding: 40;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.editProfile h2 {
  font-family: "Tenor Sans";
  font-size: 32px;
  letter-spacing: 0.05em;
  text-align: center;
}

.editProfile input {
  width: 100%;
  min-width: 400px;
  height: 36px;
  text-align: left;
  padding: 15px 23px;
  font-family: "Cormorant";
  font-weight: 500;
  font-style: italic;
  font-size: 18px;
  line-height: 19px;
  background-color: #fff;
  border: 1px solid #cbcbcb;
  border-radius: 2px;
  margin-bottom: 10px;
}

.editProfile input:focus {
  outline: none;
}

.editProfile p {
  margin-top: 10px;
  font-size: 18px;
  font-family: "Cormorant";
  font-weight: 600;
  font-style: italic;
  letter-spacing: 0.07em;
}

.editProfile p:hover {
  color: #aa4762;
  cursor: pointer;
}

/* h1 {
  color: white;
  font-size: 2.25em;
  text-align: center;
  margin-top: 1em;
  margin-bottom: 2em;
  text-shadow: 0px 2px 0px rgba(0, 0, 0, 1);
} */

.main-container {
  /* width: 100%; */
  height: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

.check-container {
  width: 10.25rem;
  height: 10.5rem;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: space-between;

  .check-background {
    width: 100%;
    height: calc(100% - 1.25rem);
    background: linear-gradient(to bottom right, #5de593, #41d67c);
    box-shadow: 0px 0px 0px 65px rgba(255, 255, 255, 0.25) inset,
      0px 0px 0px 65px rgba(255, 255, 255, 0.25) inset;
    transform: scale(0.84);
    border-radius: 50%;
    animation: animateContainer 0.75s ease-out forwards 0.75s;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;

    svg {
      /* width: 65%; */
      transform: translateY(0.25rem);
      stroke-dasharray: 80;
      stroke-dashoffset: 80;
      animation: animateCheck 0.35s forwards 1.25s ease-out;
    }
  }

  .check-shadow {
    bottom: calc(-15% - 5px);
    left: 0;
    border-radius: 50%;
    background: radial-gradient(closest-side,
        rgba(73, 218, 131, 1),
        transparent);
    animation: animateShadow 0.75s ease-out forwards 0.75s;
  }
}

@keyframes animateContainer {
  0% {
    opacity: 0;
    transform: scale(0);
    box-shadow: 0px 0px 0px 65px rgba(255, 255, 255, 0.25) inset,
      0px 0px 0px 65px rgba(255, 255, 255, 0.25) inset;
  }

  25% {
    opacity: 1;
    transform: scale(0.9);
    box-shadow: 0px 0px 0px 65px rgba(255, 255, 255, 0.25) inset,
      0px 0px 0px 65px rgba(255, 255, 255, 0.25) inset;
  }

  43.75% {
    transform: scale(1.15);
    box-shadow: 0px 0px 0px 43.334px rgba(255, 255, 255, 0.25) inset,
      0px 0px 0px 65px rgba(255, 255, 255, 0.25) inset;
  }

  62.5% {
    transform: scale(1);
    box-shadow: 0px 0px 0px 0px rgba(255, 255, 255, 0.25) inset,
      0px 0px 0px 21.667px rgba(255, 255, 255, 0.25) inset;
  }

  81.25% {
    box-shadow: 0px 0px 0px 0px rgba(255, 255, 255, 0.25) inset,
      0px 0px 0px 0px rgba(255, 255, 255, 0.25) inset;
  }

  100% {
    opacity: 1;
    box-shadow: 0px 0px 0px 0px rgba(255, 255, 255, 0.25) inset,
      0px 0px 0px 0px rgba(255, 255, 255, 0.25) inset;
  }
}

@keyframes animateCheck {
  from {
    stroke-dashoffset: 80;
  }

  to {
    stroke-dashoffset: 0;
  }
}

@keyframes animateShadow {
  0% {
    opacity: 0;
    width: 100%;
    height: 15%;
  }

  25% {
    opacity: 0.25;
  }

  43.75% {
    width: 40%;
    height: 7%;
    opacity: 0.35;
  }

  100% {
    width: 85%;
    height: 15%;
    opacity: 0.25;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Works for Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/*Header Global Search */
.navbar__search input::-webkit-input-placeholder {
  /* Edge */
  color: #FFFFFF;
}

.navbar__search input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #FFFFFF;
}

.navbar__search input::placeholder {
  color: #FFFFFF;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 12px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.item__price__tag {
  font-family: "Montserrat";
  font-size: 25px;
  font-weight: 500;
  color: #aa4762;
}

.item__add__to__cart__btn {
  font-family: "Montserrat";
  width: 200px;
  height: 52px;
  border: 1px solid black;
  background-color: #b87d8b;
  color: white;
  font-size: 12px;
  letter-spacing: 2px;
  transition: all 0.2s ease-in;
  border-radius: 4px;
}

.item__add__to__cart__btn:hover {
  background-color: white;
  color: black;
}
.shippingRefundPolicy{
    padding: 100px 120px 30px;
}
.shippingRefundPolicy h2{
    font-family: 'Montserrat';
    font-size: 1rem;
    line-height: 1.5rem;
    text-transform: none;
    letter-spacing: normal;
    font-weight: 100;
    margin: 10px 0;
    text-align: left;
}
.shippingRefundPolicy h3{
    font-family: 'Montserrat';
    font-size: 14px;
    line-height: 23px;
    font-weight: 500;
    color: #838383;
    margin: 10px 0;
    text-align: left;
}
.shippingRefundPolicy ol{
    text-align: left;
    padding-left: 0;
    font-size: 14px;
    font-family: 'Montserrat';
    font-weight: 500;
    line-height: 23px;
    color: #838383;
    list-style-position: inside;
    margin: 10px 0;
}

@media screen and (max-width: 550px) {
    .shippingRefundPolicy{
        padding: 100px 15px 30px;
    }
}
.gemsStoneGuide p{
    font-size: 1rem;
    font-family: 'Montserrat';
    line-height: 1.5rem;
    font-weight: 100;
    margin: 18px 0;
}
.gemsStoneGuide__header{
    padding: 180px 30px;
}
.gemsStoneGuide__header h2{
    font-family: 'Tenor Sans';
    font-size: 30px;
    font-weight: 500;
    line-height: 1.136em;
    color: #5a5a5a;
}
.gemsStoneGuide__content{
    padding: 30px 220px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
}
.gemsStoneGuide__content__item{
    width: 48%;
    text-align: left;
}
.gemsStoneGuide__content__item h2{
    font-family: 'Tenor Sans';
    font-size: 1.3125rem;
    line-height: 1.625rem;
    font-weight: 400;
    margin: 25px 0;
    letter-spacing: 0.045em;
    text-align: left;
}
.gemsStoneGuide__content__item h3{
    font-size: 14px;
    font-weight: 500;
    font-family: 'Montserrat';
    line-height: 23px;
    color: #838383;
    text-align: left;
}
.gemsStoneGuide__content__item p{
    text-align: left;
}

@media screen and (max-width: 550px) {
    .gemsStoneGuide__header{
        padding: 180px 15px 20px;
    }
    .gemsStoneGuide__content{
        padding: 30px 15px;
        flex-wrap: nowrap;
        flex-direction: column;
    }
    .gemsStoneGuide__content__item{
        width: 100%;
        padding: 15px;
    }
}
.jewelleryGuide{
    padding: 50px 0;
}
.jewelleryGuide h1, h2{
    font-family: 'Tenor Sans';
    font-size: 2rem;
    line-height: 1.267em;
    letter-spacing: 0.05em;
    margin-bottom: 20px;
    text-transform: capitalize;
}
.jewelleryGuide h2{
    font-size: 1.3125rem;
    line-height: 1.625rem;
    font-weight: 400;
    margin: 25px 0;
    text-align: left;
}
.jewelleryGuide p{
    font-family: 'Montserrat';
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 100;
    margin: 18px 0;
    text-align: left;
}
.jewelleryGuide__header{
    padding: 50px;
    background-color: #fbf9f9;
}
.jewelleryGuide__header2{
    padding: 50px 220px;
}
.jewelleryGuide__cards{
    padding: 0 220px;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    width: 100%;
}
.jewelleryGuide__card{
    width: 48%;
}
.jewelleryGuide__certi{
    padding: 50px 220px;
}
.jewelleryGuide__hrline{
    width: 100%;
    border-bottom: 1px solid #e1dede;
    line-height: 1em;
}
.jewelleryGuide__certi__content{
    width: 100%;
}
.jewelleryGuide__certi__content__card{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0 20px;
}
.jewelleryGuide__certi__content__card__img, .jewelleryGuide__certi__content__card__content{
    width: 48%;
}
.jewelleryGuide__certi__content__card__img img{
    max-width: 100%;
}
.jewelleryGuide__care{
    padding: 0 220px;
}
.jewelleryGuide__care p{
    max-width: 72%;
    text-align: center;
    margin: 18px auto;
}
.jewelleryGuide__care__card{
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 25px;
}
.jewelleryGuide__care__card__img{
    width: 48%;
}
.jewelleryGuide__care__card__img img{
    max-width: 100%;
}
.jewelleryGuide__care__card__content{
    width: 48%;
}
.jewelleryGuide__care__card__content ul{
    padding: 0;
    text-align: left;
    list-style-position: inside;
    /* margin: 15px 0; */
}
.jewelleryGuide__care__card__content li{
    padding-bottom: 1em;
    font-family: 'Montserrat';
    font-size: 14px;
    font-weight: 500;
    line-height: 23px;
    color: #838383;
}

@media screen and (max-width: 550px) {
    .jewelleryGuide__header{
        padding: 50px 0;
    }
    .jewelleryGuide__header2{
        padding: 50px 15px 0;
    }
    .jewelleryGuide__header2 img{
        max-width: 100%;
    }
    .jewelleryGuide__cards{
        padding: 15px;
        flex-wrap: nowrap;
        flex-direction: column;
    }
    .jewelleryGuide__card{
        width: 100%;
        padding: 0 15px;
    }
    .jewelleryGuide__certi{
        padding: 50px 15px;
    }
    .jewelleryGuide__certi__content__card{
        flex-direction: column;
        margin: 16px 0;
        padding: 0 15px;
    }
    .jewelleryGuide__certi__content__card__img, .jewelleryGuide__certi__content__card__content{
        width: 100%;
    }
    .jewelleryGuide__care{
        padding: 0 15px;
    }
    .jewelleryGuide__care p{
        max-width: 100%;
        margin-bottom: 34px;
    }
    .jewelleryGuide__care__card{
        flex-direction: column;
    }
    .jewelleryGuide__care__card__img, .jewelleryGuide__care__card__content{
        width: 100%;
    }
    .jewelleryGuide__care__card__content{
        margin-top: 16px;
    }
}
.diamond-guide h2{
    font-family: 'Tenor Sans';
    font-size: 30px;
    font-weight: 500;
    line-height: 1.136em;
    color: #5a5a5a;
}
.diamond-guide__header{
    padding: 180px 100px 150px;
    background-color: #fbf9f9;
}
.diamond-guide__header p{
    font-family: 'Montserrat';
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 500;
    max-width: 57%;
    margin: 20px auto;
}
.diamond-guide__desc{
    padding: 30px 220px;
}
.diamond-guide__desc p{
    font-family: 'Montserrat';
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 100;
    margin: 20px auto;
    max-width: 70%;
}
.diamond-guide__desc__hrline{
    width: 100%;
    border-bottom: 1px solid #b9b4b4;
    margin: 70px 0;
    line-height: 1em;
}
.diamond-guide__desc__content{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
}
.diamond-guide__desc__content__item{
    width: 48%;
}
.diamond-guide__desc__content__item h2{
    text-align: left;
}
.diamond-guide__desc__content__item img{
    max-width: 100%;
}
.diamond-guide__desc__content__item p{
    max-width: 100%;
    text-align: left;
}
.diamond-guide__shapes{
    padding: 150px 220px;
    background-color: #fbf9f9;
}
.diamond-guide__shapes__items{
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}
.diamond-guide__shapes__items p{
    font-size: 14px;
    font-weight: 500;
    font-family: 'Montserrat';
    line-height: 23px;
    color: #838383;
    margin-top: 10px;
}
.diamond-guide__certification{
    padding: 30px 220px;
}
.diamond-guide__certification p{
    font-family: 'Montserrat';
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 100;
    margin: 20px auto;
    max-width: 70%;
}

@media screen and (max-width:550px) {
    .diamond-guide__header{
        padding: 180px 15px 150px;
    }
    .diamond-guide__desc{
        padding: 50px 15px;
    }
    .diamond-guide__desc p{
        margin: 18px auto;
        max-width: 100%;
    }
    .diamond-guide__desc__content{
        flex-wrap: nowrap;
        flex-direction: column;
        gap: 20px;
    }
    .diamond-guide__desc__content__item{
        width: 100%;
        padding: 0 15px;
    }
    .diamond-guide__shapes{
        padding: 150px 15px 50px;
    }
    .diamond-guide__shapes__items{
        flex-wrap: wrap;
        padding: 15px 0px;
    }
    .diamond-guide__shapes__items div{
        width: 33%;
    }
    .diamond-guide__certification{
        padding: 50px 15px;
    }
    .diamond-guide__certification p{
        max-width: 100%;
    }
}